var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dark: "",
            dense: "",
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
          },
        },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.backPage } },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "espacamento-linhas pt-1" }, [
            _c("span", [_vm._v("Resumo")]),
            _vm._v(" "),
            _c("br"),
            _c("span", { staticClass: "caption espacamento-linhas" }, [
              _vm._v("Detalhes da Proposta"),
            ]),
          ]),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-container", { staticClass: "pa-5 mt-4" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "d-flex justify-center mt-10 pa-10" },
              [
                _c("v-progress-circular", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "body-container-pme" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-2 pa-5 mt-4",
                    attrs: { cols: "12", md: "4" },
                    on: {
                      click: function ($event) {
                        return _vm.getDatasPme()
                      },
                    },
                  },
                  [
                    _c("base-button-text", {
                      attrs: {
                        "data-test-id": "empresa",
                        title: "EMPRESA",
                        text: _vm.proposta.razaoSocial,
                        numeroProposta: _vm.proposta.nrContrato,
                        vidas:
                          _vm.proposta && _vm.proposta.propostas
                            ? _vm.proposta.propostas.reduce(
                                (acc, currentValue) => {
                                  if (currentValue.dependentes) {
                                    return (
                                      acc + currentValue.dependentes.length + 1
                                    )
                                  }
                                  return acc + 1
                                },
                                0
                              )
                            : 0,
                        color: true,
                        cnpj: _vm.proposta.cnpj,
                        status: _vm.proposta.etapa,
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.proposta.propostas, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "pa-2 pa-5 mt-4",
                      attrs: { cols: "12", md: "4" },
                      on: {
                        click: function ($event) {
                          return _vm.getDatas(item.guid)
                        },
                      },
                    },
                    [
                      _c("base-button-text", {
                        attrs: {
                          title: "TITULAR",
                          status: item.etapa,
                          vidas: item.dependentes
                            ? item.dependentes.length + 1
                            : 1,
                          "data-test-id": "beneficiarios",
                          text: item.titular.nome,
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
      ]),
      _vm.showButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-10",
              staticStyle: { bottom: "18px" },
              attrs: { fixed: "", dark: "", right: "", bottom: "", fab: "" },
              on: {
                click: function ($event) {
                  return _vm.openDialog()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.adicionaBeneficiario,
                  width: "60",
                  height: "60",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }